import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { API, HEADERS } from './constants';
import { User } from '../models/user';
import { Role } from '../models/role'; 
import {  } from './notification-menu.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private user: BehaviorSubject<User>;
  private token: BehaviorSubject<string> ;
  private id: BehaviorSubject<string>;
  private isIdSent: BehaviorSubject<boolean>;
  private activeRole: BehaviorSubject<Role>;

  constructor(private http: HttpClient) { 
    this.token = new BehaviorSubject<string>(sessionStorage.getItem('token'));
    this.user = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.id =  new BehaviorSubject<string>(sessionStorage.getItem('id'));
    this.activeRole = new BehaviorSubject<Role>({companyId : 1, companyName : 'BASIS', role:"training_admin"});
    this.isIdSent =  new BehaviorSubject<boolean>(false);
  }

  setIsIdSent(isIdSent: boolean ){
    this.isIdSent.next(isIdSent);
  }
 
  getIsIdSent(): boolean{
    return this.isIdSent.getValue();
  }

  setId(id: string ){
    this.id.next(id);
  }
 
  getId(): string{
    return this.id.getValue();
  }

  setUser(user: User ){
    this.user.next(user);
  }
 
  getUser(): User{
    return this.user.getValue();
  }

  getUserObservable(): Observable<User>{
    return this.user.asObservable();
  }

  setToken(token: string ){
    this.token.next(token);
    //sessionStorage.setItem("token", token)
  }
 
  getToken(): string{
      return this.token.getValue();
  }

  setActiveRole(activeRole: Role ){
    this.activeRole.next(activeRole);
  }
 
  getActiveRole(): Observable<Role>{
    return this.activeRole.asObservable();
  }

  getActiveRoleValue(): Role{
    return this.activeRole.getValue();
  }

  requestVerify(): Observable<any> {
    return this.http.get<Request>(
      API + '/auth',
      {
        headers: HEADERS,
        observe: 'response'
      }
    );
  }

  logout(){
    sessionStorage.removeItem('token');
    this.setToken(null)
    sessionStorage.removeItem('currentUser');
    this.setUser(null)
    sessionStorage.removeItem('id');
    this.setId(null)
    //this.currentUserSubject.next(null);
  }

}
