import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { ApiMockDataCallback, ApiMockResponseCallback, ApiMockRootRoute, ApiMockRoute, ObjectAny } from "@ng-stack/api-mock";

export class InspectionApiMockRootRoute implements ApiMockRootRoute {

  static INSPECTIONS_DB = [
    {
      inspections: [
        {
          id: 2,
          activity: {
            memberships: [1, 2],
            response: [
              {
                answer: null,
                category_id: 4,
                question: 'What time did you arrive for the Assessment',
                question_id: 3
              },
              {
                answer: null,
                category_id: 4,
                question: 'Are you wearing PPE as advised by BASIS and do you have your BASIS auditor identification card avaiable.',
                question_id: 4
              }
            ]
          }
        },
        {
          id: 2,
          activity: {
            memberships: [1, 2],
            response: [
              {
                answer: null,
                category_id: 4,
                question: 'What time did you arrive for the Assessment',
                question_id: 3
              },
              {
                answer: null,
                category_id: 4,
                question: 'Are you wearing PPE as advised by BASIS and do you have your BASIS auditor identification card avaiable.',
                question_id: 4
              }
            ]
          }
        }
      ]
    }
  ]

  host?: string;
  path: string;
  dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
  propertiesForList?: ObjectAny;
  responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
  ignoreDataFromLocalStorage?: boolean;
  children?: ApiMockRoute[];

  constructor() {
    this.dataCallback = this.getDataCallback()
    this.responseCallback = this.getResponseCallback();
  }

  private getDataCallback(): ApiMockDataCallback {
    return ({ httpMethod, items }) => {
      if (httpMethod == 'GET') {
        return InspectionApiMockRootRoute.INSPECTIONS_DB;
      } else {
        return items;
      }
    }
  }

  private getResponseCallback() {
    return ({ resBody, httpMethod }) => {

      if (httpMethod == 'GET') {

        if (resBody.length <= 1) {
          resBody = resBody[0]
        }

        return new HttpResponse({
          body: resBody,
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          url: 'api/register',
          statusText: 'user verify',
          status: 200,
        });

      }
      else if (httpMethod == 'POST') {
        console.log('entro post')
      }
    }
  }
}
