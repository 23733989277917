import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/provider';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    token: string;
    id: string;

    constructor(private authenticationService: AuthenticationService) {
        //this.authenticationService.getToken().subscribe(token => { this.token = token });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        this.token = this.authenticationService.getToken();
        this.id = this.authenticationService.getId();

        // console.log("Admin " + window.btoa(this.id + ":" + this.token))

        if (this.token!==null) {
            request = request.clone({ setHeaders: {Authorization: "Admin " + window.btoa(this.id + ":" + this.token)}});
        }

        return next.handle(request);
    }
}