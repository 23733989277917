import { ApiMockRootRoute } from "@ng-stack/api-mock";
import { API } from "src/environments/environment";
import { AuditorApiMockRootRoute } from "./implementation/auditor-api-mock-root-route";
import { CategoryApiMockRootRoute } from "./implementation/category-api-mock-root-route";
import { InspectionApiMockRootRoute } from "./implementation/inspection-api-mock-root-route";
import { QuestionApiMockRootRoute } from "./implementation/question-api-mock-root-route";
import { SchemeApiMockRootRoute } from "./implementation/scheme-api-mock-root-route";
import { SectionApiMockRootRoute } from "./implementation/section-api-mock-root-route";
import { VerifyApiMockRootRoute } from "./implementation/verify-api-mock-root-route";

export class MockRoute {

    private static ROUTES: MockRoute[] = [];

    static readonly VERIFY = new MockRoute('auth', true, new VerifyApiMockRootRoute);
    static readonly INSPECTIONS = new MockRoute('assignments/:id', true, new InspectionApiMockRootRoute);
    static readonly AUDITORS = new MockRoute('auditor/:id', true, new AuditorApiMockRootRoute);
    static readonly CATEGORY = new MockRoute('datastore/categories/:id', true, new CategoryApiMockRootRoute)
    static readonly SCHEME = new MockRoute('datastore/schemes/:id', true, new SchemeApiMockRootRoute)
    static readonly QUESTION = new MockRoute('datastore/questions/:id', true, new QuestionApiMockRootRoute)
    static readonly SECTION = new MockRoute('datastore/sections/:id', true, new SectionApiMockRootRoute)

    public static values(): MockRoute[] {
        return [...MockRoute.ROUTES];
    }


    private apiMockRootRoute: ApiMockRootRoute
    private implemented: boolean;

    private constructor(path: string, implemented: boolean, apiMockRootRoute: ApiMockRootRoute) {

        this.implemented = implemented;
        this.apiMockRootRoute = apiMockRootRoute;

        this.apiMockRootRoute.path = path;
        this.apiMockRootRoute.host = API

        MockRoute.ROUTES.push(this);
    }

    public isImplemented(): boolean {
        return this.implemented;
    }

    public getApiRoute(): ApiMockRootRoute {
        return this.apiMockRootRoute;
    }
}
