import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./components/pages/public/login-page/login-page.module').then(m => m.LoginPageModule) },
  { path: 'app', canActivate: [IsAuthenticatedGuard],loadChildren: () => import('./components/pages/private/main-app-page/main-app-page.module').then(m => m.MainAppPageModule) },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
