import { HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ApiMockDataCallback, ApiMockResponseCallback, ApiMockRootRoute, ApiMockRoute, ObjectAny } from "@ng-stack/api-mock";

export class VerifyApiMockRootRoute implements ApiMockRootRoute {

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.responseCallback = this.getResponseCallback();
    }

    private getResponseCallback(): ApiMockResponseCallback<ObjectAny[], ObjectAny[]> {
        console.log('HTTP api/verify');
        return ({ reqBody, reqHeaders, httpMethod }) => {

            const { Authorization } = reqHeaders;
            let [scheme, encstr] = Authorization.split(' ');
            let [user, password] = window.atob(encstr).split(':');

            if (httpMethod == 'GET') {

                /* if (user === 'auditor' && password === 'auditorpass') { */
                if (user === 'testingadmin' && password === 'testingadmin') {

                    return new HttpResponse({
                        body: {
                            verify: "true",
                        },
                        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                        url: 'api/register',
                        statusText: 'user verify',
                        status: 200,
                    });
                }
                else {

                    return new HttpErrorResponse({
                        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                        url: 'api/register',
                        statusText: 'user not verify',
                        status: 401,
                    });

                }
            }
        }
    }
}