import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate{

  token: string;
  user: User;

  constructor(private authenticationService:AuthenticationService, private router:Router, private route:ActivatedRoute) {
   
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.token = this.authenticationService.getToken();
      this.user = this.authenticationService.getUser();
     
      if(this.token && this.user){
        return true;
      }
      this.router.navigate(['/login/id'], { relativeTo: this.route });
      return false;
}

}