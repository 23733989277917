import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private active: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private sidebarContent = {
    training_admin: {
      active: 'Training',
      content: [
        {
          type: 'divider',
          label: 'Training',
          icon: 'user-solid',
        },
        {
          type: 'title',
          label: 'Training',
          icon: 'user-solid',
          route: 'training/dashboard',
          children: [
            {
              type: 'subtitle',
              label: 'Users',
              route: 'training/users',
            },
            {
              type: 'subtitle',
              label: 'Events',
              route: 'training/trainings',
            },
            {
              type: 'subtitle',
              label: 'Diploma',
              route: 'training/diploma',
            },
            {
              type: 'subtitle',
              label: 'Request',
              route: 'training/requests',
            },
          ],
        },
      ],
    },
    store_admin: {
      active: 'Inspections',
      content: [
        {
          type: 'divider',
          label: 'Store',
          icon: 'user-solid',
        },
        {
          type: 'title',
          label: 'Inspections',
          icon: 'eye-regular',
          route: 'store/inspections/dashboard',
          children: [
            {
              type: 'subtitle',
              label: 'Categories',
              route: 'store/inspections/categories',
            },
            {
              type: 'subtitle',
              label: 'Questions',
              route: 'store/inspections/questions',
            },
            {
              type: 'subtitle',
              label: 'Sections',
              route: 'store/inspections/sections',
            },
            {
              type: 'subtitle',
              label: 'Memberships',
              route: 'store/inspections/memberships',
            },
            {
              type: 'subtitle',
              label: 'Schemes',
              route: 'store/inspections/schemes',
            },
            {
              type: 'subtitle',
              label: 'Inspections',
              route: 'store/inspections/inspections',
            },
          ],
        },
        {
          type: 'title',
          label: 'Activities',
          icon: 'hard-hat-solid',
          route: 'store/activities/dashboard',
          children: [
            {
              type: 'subtitle',
              label: 'Requests',
              route: 'store/activities/requests',
            },
          ],
        },
        {
          type: 'title',
          label: 'Organisations',
          icon: 'building-solid',
          route: 'store/organisations/dashboard',
          children: [
            {
              type: 'subtitle',
              label: 'Requests',
              route: 'store/organisations/requests',
            },
            {
              type: 'subtitle',
              label: 'Organisations',
              route: 'store/organisations/organisations',
            },
          ],
        },
        {
          type: 'title',
          label: 'Auditors',
          icon: 'user-solid',
          route: 'store/auditors/dashboard',
          children: [
            {
              type: 'subtitle',
              label: 'Expenses',
              route: 'store/auditors/expenses',
            },
            {
              type: 'subtitle',
              label: 'Auditors',
              route: 'store/auditors/auditors',
            },
          ],
        },
      ],
    },
  };

  constructor() {}

  getSidebarContent() {
    return this.sidebarContent;
  }

  setActive(active: string) {
    if (active !== this.active.getValue()) {
      this.active.next(active);
    }
  }

  getActive(): Observable<string> {
    return this.active.asObservable();
  }

  setHidden(hidden: boolean) {
    this.hidden.next(hidden);
  }

  getHidden(): boolean {
    return this.hidden.getValue();
  }
}
