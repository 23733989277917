import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserMenuService {

  private hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private outside: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private activeRole: BehaviorSubject<string> = new BehaviorSubject<string>('trainee');

  constructor() { }

  setHidden(hidden: boolean ){
    this.hidden.next(hidden);
  }
 
  getHidden(): boolean{
    return this.hidden.getValue();
  } 

  setOutside(outside: boolean ){
    this.outside.next(outside);
  }
 
  getOutside(): boolean{
    return this.outside.getValue();
  } 

  setActiveRole(activeRole: string ){
    this.activeRole.next(activeRole);
  }
 
  getActiveRole(): Observable<string>{
    return this.activeRole.asObservable();
  }

}
