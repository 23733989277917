import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private device: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private modalData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  setDevice(device: string ){
    this.device.next(device);
  }
 
  getDevice(): Observable<string>{
    return this.device.asObservable();
  } 

  setHidden(hidden: boolean ){
    this.hidden.next(hidden);
  }
 
  getHidden(): Observable<boolean>{
    return this.hidden.asObservable();
  } 

  setModalData(modalData: any ){
    this.modalData.next(modalData);
  }
 
  getModalData(): any{
    return this.modalData.getValue();
  } 

}

